import { Button, DialogActions, Grid, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { EmailInput } from '@/components';

import { siteSettingsSchema } from './schema';
import { styles } from './styles';
import { useSiteSettingsForm } from './useSiteSettingsForm';

const SiteSettingsForm = () => {
  const { initialValues, isUpdateLoading, isCreateLoading, onSubmit } =
    useSiteSettingsForm();
  return (
    <Formik
      enableReinitialize
      validationSchema={siteSettingsSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Box sx={styles.container}>
            <Grid container spacing={styles.containerSpacing}>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="adminEmail"
                  autoComplete="adminEmail"
                  fullWidth
                  component={EmailInput}
                  placeholder="Admin Email"
                />
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Field
                  type="text"
                  name="recruiterEmail"
                  autoComplete="recruiterEmail"
                  fullWidth
                  component={EmailInput}
                  placeholder="Recruiter Email"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                sx={styles.btn}
                disabled={!dirty || isUpdateLoading || isCreateLoading}
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SiteSettingsForm;
