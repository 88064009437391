import { createSelector } from '@reduxjs/toolkit';

import type { IMetadatasState } from './types';
import type { RootState } from '@/redux/store';

export const metadatasStateSelector = (state: RootState) => state.metadatas;

export const selectedMetadataForModalSelector = createSelector(
  metadatasStateSelector,
  (state: IMetadatasState) => state.selectedMetadataForModal
);

export const isDeleteMetadataModalOpenSelector = createSelector(
  metadatasStateSelector,
  (state: IMetadatasState) => state.isDeleteMetadataModalOpen
);
