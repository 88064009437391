import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { SelectInput, TextInput } from '@/components';
import { capitalize } from '@/utils';

import { feedbackStatuses } from './constants';
import { styles } from './styles';
import { useEditFeedbackPage } from './useEditFeedbackPage';

const EditFeedbackPage: React.FC = () => {
  const {
    initialFeedbackValues,
    isEditFeedbackLoading,
    onFeedbackSubmit,
    downloadAttachmentFile,
  } = useEditFeedbackPage();

  return (
    <Container>
      <Typography variant="h4" my={2}>
        Feedback #{initialFeedbackValues.reqId}
      </Typography>
      {initialFeedbackValues.position ? (
        <Typography variant="h5" my={2}>
          Position: {initialFeedbackValues.position}
        </Typography>
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialFeedbackValues}
        onSubmit={onFeedbackSubmit}
      >
        {({ dirty }) => (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="type"
                  autoComplete="type"
                  fullWidth
                  component={TextInput}
                  placeholder="Type"
                  disabled
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="status"
                  placeholder="Status"
                  component={SelectInput}
                  size="small"
                >
                  {feedbackStatuses.map((status) => (
                    <MenuItem value={status} key={status}>
                      <Typography>{capitalize(status)}</Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  name="name"
                  autoComplete="name"
                  fullWidth
                  component={TextInput}
                  placeholder="Name"
                  disabled
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="email"
                  autoComplete="email"
                  fullWidth
                  component={TextInput}
                  placeholder="Email"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  name="company"
                  autoComplete="company"
                  fullWidth
                  component={TextInput}
                  placeholder="Company"
                  disabled
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h6" mb={1}>
                  Attachment
                </Typography>
                <Button
                  variant="contained"
                  onClick={downloadAttachmentFile}
                  disabled={!initialFeedbackValues.file}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  name="createdAt"
                  autoComplete="createdAt"
                  fullWidth
                  component={TextInput}
                  placeholder="Created"
                  disabled
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="updatedAt"
                  autoComplete="updatedAt"
                  fullWidth
                  component={TextInput}
                  placeholder="Updated"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  name="message"
                  autoComplete="message"
                  fullWidth
                  component={TextInput}
                  placeholder="Message"
                  disabled
                  multiline
                />
              </Grid>
            </Grid>
            <Box sx={styles.btnWrapper}>
              <Button
                variant="contained"
                type="submit"
                disabled={isEditFeedbackLoading || !dirty}
                sx={styles.btn}
              >
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditFeedbackPage;
