import { createSelector } from '@reduxjs/toolkit';

import type { IVacanciesState } from './types';
import type { RootState } from '@/redux/store';

export const vacanciesStateSelector = (state: RootState) => state.vacancies;

export const selectedVacancyForModalSelector = createSelector(
  vacanciesStateSelector,
  (state: IVacanciesState) => state.selectedVacancyForModal
);

export const isDeleteVacancyModalOpenSelector = createSelector(
  vacanciesStateSelector,
  (state: IVacanciesState) => state.isDeleteVacancyModalOpen
);
