import { Container, Typography } from '@mui/material';
import React from 'react';

import { MetadataForm } from '@/components/forms/MetadataForm/MetadataForm';

import { useEditMetadataPage } from './useEditMetadataPage';

export const EditMetadataPage: React.FC = () => {
  const { initialMetadataValues, isEditMetadataLoading, onEditMetadataSubmit } =
    useEditMetadataPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        Metadata #{initialMetadataValues.reqId}
      </Typography>
      <MetadataForm
        isLoading={isEditMetadataLoading}
        onSubmit={onEditMetadataSubmit}
        initialValues={initialMetadataValues}
      />
    </Container>
  );
};
