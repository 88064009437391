import { Button, Container, Typography } from '@mui/material';
import React from 'react';

import { useRemovePreviewImagesMutation } from "@/apis/website-settings";
import { SiteSettingsForm } from '@/components';
import { ResponseStatus } from "@/constants";
import { useToastMessageRequest } from "@/hooks";

const DEFAULT_SUCCESS_MESSAGE = 'The request was completed successfully';

const SiteSettingsPage = () => {
  const [removePreviewImages, { data: removePreviewsData, isSuccess, ...requestParams }] = useRemovePreviewImagesMutation();

  useToastMessageRequest({ isSuccess, ...requestParams }, {
      condition: isSuccess,
      message: {
          text: removePreviewsData?.message || DEFAULT_SUCCESS_MESSAGE,
          status: ResponseStatus.Success,
      },
  });

  const handleRemoveImages = () => {
      removePreviewImages();
  }

  return (
    <Container>
      <Typography variant="h5" mb={2}>
        Website settings
      </Typography>
      <SiteSettingsForm />
      <Typography variant="h5" mb={2}>
        Preview images
      </Typography>
      <Button
          variant="contained"
          disableRipple
          onClick={handleRemoveImages}
      >
          Remove preview images
      </Button>
    </Container>
  );
};

export default SiteSettingsPage;
