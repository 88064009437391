import { object, string } from 'yup';

const META_TITLE_MAX_SYMBOLS = 60;
const META_DESCRIPTION_MIN_SYMBOLS = 50;
const META_DESCRIPTION_MAX_SYMBOLS = 160;

const KEYWORDS_VALIDATION_REGEX = /^([a-zA-Z]+(?:,|$)\s*)+$/;

const URL_VALIDATION_REGEX =
  /^\/[a-zA-Z0-9\-._~%!$&'()*+,;=:@/]+(\?[a-zA-Z]+=[a-zA-Z0-9]+(&[a-zA-Z]+=[a-zA-Z0-9]+)*)?$/;

const validationErrorMessages = {
  title: {
    required: 'Meta title is a required field',
    max: `Meta title should consists of maximum ${META_TITLE_MAX_SYMBOLS} characters`,
  },
  description: {
    required: 'Meta description is a required field',
    min: `Meta description must be longer than or equal to ${META_DESCRIPTION_MIN_SYMBOLS} characters`,
    max: `Meta description must be no longer than ${META_DESCRIPTION_MAX_SYMBOLS} characters`,
  },
  keywords: {
    required: 'Keywords is a required field',
    matches: 'Invalid format. Please provide words separated by commas.',
  },
  h1: { required: 'H1 is a required field' },
  url: {
    required: 'Url is a required field',
    matches:
      'url should be a string without host, domen and port. Example: /blog/posts/2022?page=2&limit=10',
  },
  imageUrl: {
    required: 'Image url is a required field',
    url: 'Image url should be a valid url string',
  },
  urlPrefix: {
    url: 'Prefix url should be a valid url string',
  },
};

export const metadataFormSchema = object({
  title: string()
    .trim()
    .required(validationErrorMessages.title.required)
    .max(META_TITLE_MAX_SYMBOLS, validationErrorMessages.title.max),
  subtitle: string().trim(),
  description: string()
    .trim()
    .required(validationErrorMessages.description.required)
    .min(META_DESCRIPTION_MIN_SYMBOLS, validationErrorMessages.description.min)
    .max(META_DESCRIPTION_MAX_SYMBOLS, validationErrorMessages.description.max),
  keywords: string()
    .trim()
    .required(validationErrorMessages.keywords.required)
    .matches(
      KEYWORDS_VALIDATION_REGEX,
      validationErrorMessages.keywords.matches
    ),
  h1: string().trim().required(validationErrorMessages.h1.required),
  url: string()
    .trim()
    .required(validationErrorMessages.url.required)
    .matches(URL_VALIDATION_REGEX, validationErrorMessages.url.matches),
  urlPrefix: string().trim().url(validationErrorMessages.urlPrefix.url),
  imageUrl: string()
    .trim()
    .required(validationErrorMessages.imageUrl.required)
    .url(validationErrorMessages.imageUrl.url),
});
