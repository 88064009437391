// import { FilterTypes } from '@/types';

import {FilterTypes} from "@/types";

import type { FiltersType } from '@/types';
export const headCells = [
  { title: 'Id', width: 100 },
  { title: 'Date', width: 280 },
  { title: 'Type', width: 280 },
  { title: 'Title', width: 180 },
  { title: 'Image', width: 180 },
  { title: 'Created', width: 180 },
  { title: 'Updated', width: 180 },
  { title: 'Actions', width: 180 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  null,
  { name: 'type', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  null,
  null,
  null,
  null,
];
