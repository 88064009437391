import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';

export const headCells = [
  { title: 'Id', width: 80 },
  { title: 'Url', width: 280 },
  { title: 'Title', width: 180 },
  { title: 'H1', width: 180 },
  { title: 'Description', width: 240 },
  { title: 'Keywords', width: 150 },
  { title: 'Subtitle', width: 100 },
  { title: '', width: 120 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'url', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  { name: 'h1', type: FilterTypes.Text },
  { name: 'description', type: FilterTypes.Text },
  { name: 'keywords', type: FilterTypes.Text },
  { name: 'subtitle', type: FilterTypes.Text },
  null,
];
