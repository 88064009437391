import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/redux/store';

export const historyStateSelector = (state: RootState) => state.history;

export const selectedHistoryForModalSelector = createSelector(
  historyStateSelector,
  //todo
  (state: any) => state.selectedHistoryForModal
);

export const isDeleteHistoryModalOpenSelector = createSelector(
  historyStateSelector,
  //todo
  (state: any) => state.isDeleteHistoryModalOpen
);
