import { Box, Link, List, Toolbar } from '@mui/material';
import React from 'react';

import { NavItem } from '@/components';
import { navigationList } from '@/constants';

import { styles } from './styles';

const Sidebar: React.FC = () => (
  <Box sx={styles.container}>
    <Toolbar>
      <Link href="/" sx={styles.title} variant="h4">
        Wetelo Admin
      </Link>
    </Toolbar>
    <List>
      {navigationList.map((item) => (
        <NavItem {...item} key={item.name} />
      ))}
    </List>
  </Box>
);

export default Sidebar;
