import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse, IVacancyResponse } from '@/types';

const { api } = envUtil.getEnv();

export const vacanciesApi = createApi({
  reducerPath: 'vacanciesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Vacancies', 'Vacancy'],
  endpoints: (builder) => ({
    getVacancies: builder.query<IResponse<IVacancyResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Vacancies,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Vacancies' as const,
                id,
              })),
              { type: 'Vacancies', id: 'LIST' },
            ]
          : [{ type: 'Vacancies', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getVacancyById: builder.query<IVacancyResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Vacancies}/${id}`,
      }),
      providesTags: () => ['Vacancies'],
    }),
    createVacancy: builder.mutation<IVacancyResponse, IVacancyResponse>({
      query: (body) => ({
        url: `${ApiRoutes.Vacancies}`,
        body,
        method: 'POST',
      }),
    }),
    editVacancy: builder.mutation<IVacancyResponse, IVacancyResponse>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Vacancies}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Vacancies'],
    }),
    deleteVacancy: builder.mutation<void, string>({
      query: (id) => ({
        url: `${ApiRoutes.Vacancies}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Vacancies', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetVacanciesQuery,
  useCreateVacancyMutation,
  useDeleteVacancyMutation,
  useGetVacancyByIdQuery,
  useEditVacancyMutation,
} = vacanciesApi;
