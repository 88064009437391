import { useNavigate } from 'react-router-dom';

import {useGetHistoryPointQuery} from "@/apis/history";
import {SortingOrderEnum} from "@/enums/sorting/sortingOrder";
import {usePagination, useTableFilters} from '@/hooks';
import {filteringFields} from "@/views/historyPoints/MainHistoryPointsPage/constants";

export const useMainHistoryPointsPage = () => {
  const navigate = useNavigate();
  const [filters, onFiltersChange, onSortingChange, sortBy, isOrderByAsc] =
    useTableFilters(filteringFields);
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data, isLoading } = useGetHistoryPointQuery({
    limit,
    page: page + 1,
    sortBy,
    ...(isOrderByAsc !== undefined && {
      order: isOrderByAsc ? SortingOrderEnum.ASC : SortingOrderEnum.DESC,
    }),
    ...filters,
  });

  //todo
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const count = 10 * limit;

  //todo
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const noData = false;

  const goToCreateHistoryPointPage = () => {
    navigate('create');
  };

  return {
    historyPoints: data,
    count,
    noData,
    isLoading,
    onFiltersChange,
    goToCreateHistoryPointPage,
    onSortingChange,
    sortBy,
    isOrderByAsc,
    ...paginationParams,
  };
};
