import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { IRemovePreviewImagesResponse } from "@/types";
import { envUtil } from '@/utils';

import type {
  IWebsiteSettingsResponse,
  IWebsiteSettingsFormParams,
} from '@/types';

const { api } = envUtil.getEnv();

export const websiteSettingsApi = createApi({
  reducerPath: 'websiteSettingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Settings'],

  endpoints: (builder) => ({
    getWebsiteSettings: builder.query<IWebsiteSettingsResponse, void>({
      query: () => ({
        url: ApiRoutes.WebsiteSettings,
      }),
      providesTags: () => ['Settings'],
    }),
    updateWebsiteSettings: builder.mutation<
      IWebsiteSettingsResponse,
      IWebsiteSettingsFormParams
    >({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.WebsiteSettings}/${id}`,
        body,
        method: 'PUT',
      }),
    }),
    createWebsiteSettings: builder.mutation<
      IWebsiteSettingsResponse,
      IWebsiteSettingsFormParams
    >({
      query: ({ id, ...body }) => ({
        url: ApiRoutes.WebsiteSettings,
        body,
        method: 'POST',
      }),
    }),
    removePreviewImages: builder.mutation<IRemovePreviewImagesResponse, void>({
      query: () => ({
        url: `${ApiRoutes.PreviewImages}/remove-images`,
        method: 'DELETE'
      })
    })
  }),
});

export const {
  useGetWebsiteSettingsQuery,
  useUpdateWebsiteSettingsMutation,
  useCreateWebsiteSettingsMutation,
  useRemovePreviewImagesMutation,
} = websiteSettingsApi;
