import {
  MetadataSortingByEnum,
  PostsSortingByEnum,
  FeedbacksSortingEnum,
  VacanciesSortingByEnum,
} from '@/enums/sorting/sortingBy';

export const sortingFields = {
  ...MetadataSortingByEnum,
  ...PostsSortingByEnum,
  ...FeedbacksSortingEnum,
  ...VacanciesSortingByEnum,
};
