import { createSelector } from '@reduxjs/toolkit';

import type { IPostsState } from './types';
import type { RootState } from '@/redux/store';

export const postsStateSelector = (state: RootState) => state.posts;

export const selectedPostForModalSelector = createSelector(
  postsStateSelector,
  (state: IPostsState) => state.selectedPostForModal
);

export const isDeletePostModalOpenSelector = createSelector(
  postsStateSelector,
  (state: IPostsState) => state.isDeletePostModalOpen
);
