import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteVacancyModal } from './useDeleteVacancyModal';

const DeleteVacancyModal: React.FC = () => {
  const { isOpen, vacancy, closeModal, confirmDeleting } =
    useDeleteVacancyModal();

  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete position #${
        vacancy?.reqId ?? ''
      }?`}
    />
  );
};

export default DeleteVacancyModal;
