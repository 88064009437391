import { Container, Typography } from '@mui/material';
import React from 'react';

import { VacancyForm } from '@/components';
import { postFormSchema } from '@/components/forms/PostForm/schemas';

import { useEditVacancyPage } from './useEditVacancyPage';

const EditPostPage: React.FC = () => {
  const { initialVacancyValues, isEditVacancyLoading, onVacancySubmit } =
    useEditVacancyPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        Vacancy #{initialVacancyValues.reqId}
      </Typography>
      <VacancyForm
        validationSchema={postFormSchema}
        initialValues={initialVacancyValues}
        isLoading={isEditVacancyLoading}
        onSubmit={onVacancySubmit}
      />
    </Container>
  );
};

export default EditPostPage;
