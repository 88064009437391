import { Container } from '@mui/material';
import React from 'react';

import { VacancyForm } from '@/components';

import { useCreateVacancyPage } from './useCreateVacancyPage';

const CreateVacancyPage = () => {
  const props = useCreateVacancyPage();
  return (
    <Container>
      <VacancyForm {...props} />
    </Container>
  );
};

export default CreateVacancyPage;
