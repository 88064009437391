import { useNavigate } from 'react-router-dom';

import { useGetVacanciesQuery } from '@/apis/vacancies';
import { SortingOrderEnum } from '@/enums/sorting/sortingOrder';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useVacanciesPage = () => {
  const navigate = useNavigate();
  const [filters, onFiltersChange, onSortingChange, sortBy, isOrderByAsc] =
    useTableFilters(filteringFields);
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: vacanciesData, isLoading } = useGetVacanciesQuery({
    limit,
    page: page + 1,
    sortBy,
    ...(isOrderByAsc !== undefined && {
      order: isOrderByAsc ? SortingOrderEnum.ASC : SortingOrderEnum.DESC,
    }),
    ...filters,
  });

  const count = (vacanciesData?.meta?.totalPages ?? 0) * limit;

  const noData = !vacanciesData?.items.length;

  const goToCreatePostPage = () => {
    navigate('create');
  };

  return {
    vacanciesData,
    count,
    noData,
    isLoading,
    onFiltersChange,
    goToCreatePostPage,
    onSortingChange,
    sortBy,
    isOrderByAsc,
    ...paginationParams,
  };
};
