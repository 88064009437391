import { useEffect, useState } from 'react';

import { useCheckAuthQuery } from '@/apis/auth';
import { getDefaultAuthContext } from '@/utils';

import type { IAuthContext } from '@/types';

export const useAuthProvider = () => {
  const { data: user, isLoading } = useCheckAuthQuery();
  const [value, setValue] = useState<IAuthContext>(getDefaultAuthContext());

  useEffect(() => {
    if (!isLoading) {
      setValue({ ...value, user: user ?? null, isLoading });
    }
    console.log(`user from auth provider: ${JSON.stringify(user)}`);
  }, [user, isLoading]);

  return value;
};
