import { Close } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { useUploadAttachmentMutation } from '@/apis/attachments';
import { DropZoneIcon } from '@/components/icons';

import { styles } from './styles';
import { IImageInputProps } from './types';

import type { MouseEvent } from 'react';

const acceptImageTypse = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg'],
};

function ImageInput<
  FormValues extends Record<string, unknown> = Record<string, unknown>
>({
  field: { value, name, onBlur },
  form: { touched, errors, setFieldValue },
  placeholder,
}: IImageInputProps<FormValues>) {
  const preview = value?.url;
  const hasError = Boolean(touched[name] && errors[name]);

  const [uploadAttachments, { isLoading }] = useUploadAttachmentMutation();

  const onDrop = async (files: File[]) => {
    if (files.length) {
      const res = await uploadAttachments([files[0]]);

      if ('error' in res) {
        return;
      }

      setFieldValue(name, {
        id: res.data.attachments[0].id,
        url: res.data.attachments[0].url,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptImageTypse,
    onDrop,
    multiple: false,
    maxFiles: 1,
  });

  const onDeleteIcon = (e: MouseEvent<HTMLElement>) => {
    setFieldValue(name, null);
    e.stopPropagation();
  };

  return (
    <FormControl fullWidth>
      <Typography variant="h6" mb={1}>
        {placeholder}
      </Typography>
      <Box
        {...getRootProps()}
        sx={[styles.zone, isDragActive ? styles.active : null]}
      >
        {isLoading && (
          <Box sx={styles.imageUploadContainer}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && value && (
          <>
            <IconButton sx={styles.iconBtn} onClick={onDeleteIcon}>
              <Close />
            </IconButton>
            <Box sx={styles.previewContainer}>
              <Box component="img" src={preview} sx={styles.img} />
            </Box>
          </>
        )}

        {!isLoading && !value && (
          <Box sx={styles.imageUploadContainer}>
            <input
              accept="image/png, image/jpeg"
              {...getInputProps()}
              onBlur={onBlur}
            />
            <DropZoneIcon sx={styles.icon} />
            <Typography variant="h5">Add Image</Typography>
            <Typography variant="caption" sx={styles.caption}>
              {isDragActive
                ? 'Drop the image here...'
                : 'Upload image from your computer or drag files here.'}
            </Typography>
          </Box>
        )}
      </Box>
      <FormHelperText error={hasError}>
        {(hasError ? errors[name] : ' ') as string}
      </FormHelperText>
    </FormControl>
  );
}

export default ImageInput;
