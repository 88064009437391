import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEditMetadataMutation,
  useGetMetadataByIdQuery,
} from '@/apis/metadata';
import { IMetadataValues } from '@/components/forms/MetadataForm/types';
import { useToastMessageRequest } from '@/hooks';

export const useEditMetadataPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: metadata } = useGetMetadataByIdQuery(id);
  const [
    editMetadata,
    { isLoading: isEditMetadataLoading, ...editMetadataRequestParams },
  ] = useEditMetadataMutation();

  useToastMessageRequest(editMetadataRequestParams);

  const initialMetadataValues = useMemo(
    () => ({
      reqId: metadata?.reqId ?? null,
      title: metadata?.title ?? '',
      subtitle: metadata?.subtitle ?? '',
      description: metadata?.description ?? '',
      keywords: metadata?.keywords ?? '',
      h1: metadata?.h1 ?? '',
      url: metadata?.url ?? '',
      urlPrefix: metadata?.urlPrefix ?? '',
      imageUrl: metadata?.imageUrl ?? '',
    }),
    [metadata]
  );

  const onEditMetadataSubmit = useCallback(
    (values: IMetadataValues) => {
      editMetadata({
        id,
        ...values,
      });
    },
    [id]
  );

  return {
    initialMetadataValues,
    isEditMetadataLoading,
    onEditMetadataSubmit,
  };
};
