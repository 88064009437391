export const postLang = ['en'];

export const defaultInitialValues = {
  title: '',
  subtitle: '',
  description: '',
  content: [
    {
      type: 'HTML',
      data: {
        content: '',
      },
    },
  ],
  image: undefined,
  lang: 'en',
  quote: '',
  metaTitle: '',
  metaH1: '',
  metaDescription: '',
  metaKeywords: '',
  metaSubTitle: '',
  permalink: '',
  author: '',
  authorPosition: '',
  noIndex: false,
};

export const categories = [
  'Quality Assurance',
  'Digitalization',
  'Workflow',
  'Business',
  'Culture',
  'Data',
  'Special',
];
