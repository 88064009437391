import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, colors } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTableItem } from '@/hooks';
import { openDeleteMetadataModal } from '@/redux/metadatas/slice';
import { IMetadataResponse } from '@/types/responses/metadata';
import { truncateString } from '@/utils';

export const MetadataTableItem: React.FC<IMetadataResponse> = (metadata) => {
  const { id, title, url, h1, description, keywords, subtitle, reqId } =
    metadata;

  const { onEdit, onDelete } = useTableItem(openDeleteMetadataModal);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Link
          to={`edit/${id}`}
          style={{ textDecoration: 'none', color: colors.blue[600] }}
        >
          {reqId}
        </Link>
      </TableCell>
      <TableCell component="th" scope="row">
        {url}
      </TableCell>
      <TableCell component="th" scope="row">
        {truncateString(title)}
      </TableCell>
      <TableCell component="th" scope="row">
        {h1}
      </TableCell>
      <TableCell component="th" scope="row">
        {description}
      </TableCell>
      <TableCell component="th" scope="row">
        {keywords}
      </TableCell>
      <TableCell component="th" scope="row">
        {subtitle}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(metadata)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
