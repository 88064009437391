import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { IMetadataValues } from '@/components/forms/MetadataForm/types';
import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { IMetadataResponse } from '@/types/responses/metadata';
import { envUtil } from '@/utils';

import type { IMetadataRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const metadataApi = createApi({
  reducerPath: 'metadataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Metadatas', 'Metadata'],
  endpoints: (builder) => ({
    getMetadatas: builder.query<
      IResponse<IMetadataResponse>,
      IMetadataRequestParams
    >({
      query: (params) => ({
        url: ApiRoutes.Metadatas,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Metadatas' as const,
                id,
              })),
              { type: 'Metadatas', id: 'LIST' },
            ]
          : [{ type: 'Metadatas', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getMetadataById: builder.query<IMetadataResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Metadatas}/${id}`,
      }),
      providesTags: () => ['Metadata'],
    }),
    createMetadata: builder.mutation<IMetadataResponse, IMetadataValues>({
      query: (body) => ({
        url: `${ApiRoutes.Metadatas}`,
        body,
        method: 'POST',
      }),
    }),
    editMetadata: builder.mutation<IMetadataResponse, { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Metadatas}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Metadata'],
    }),
    deleteMetadata: builder.mutation<void, string>({
      query: (id) => ({
        url: `${ApiRoutes.Metadatas}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Metadatas', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetMetadatasQuery,
  useGetMetadataByIdQuery,
  useCreateMetadataMutation,
  useEditMetadataMutation,
  useDeleteMetadataMutation,
} = metadataApi;
