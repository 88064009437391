import { IMetadataValues } from './types';

export const defaultInitialValues: IMetadataValues = {
  title: '',
  subtitle: '',
  description: '',
  keywords: '',
  h1: '',
  url: '',
  urlPrefix: '',
  imageUrl: '',
};
