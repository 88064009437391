import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateVacancyMutation } from '@/apis/vacancies';
import { Pathes } from '@/constants';
import { useToastMessageRequest } from '@/hooks';

export const useCreateVacancyPage = () => {
  const [createVacancy, { isLoading, isSuccess, ...requestParams }] =
    useCreateVacancyMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Vacancies);
    }
  }, [isSuccess]);

  const onSubmit = useCallback(createVacancy, []);

  return { isLoading, onSubmit };
};
