
export const HISTORY_POINTS_TYPES = [
  'date_point', 'break_point'
];
export const defaultInitialValues = {
  date: new Date().getDate(),
  type: HISTORY_POINTS_TYPES[0],
  title: '',
  description: '',
  image: undefined,
  imageAlt: '',
  isReverse: false,
  isRightSide: false,
};


