import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEditVacancyMutation,
  useGetVacancyByIdQuery,
} from '@/apis/vacancies';
import { useToastMessageRequest } from '@/hooks';

export const useEditVacancyPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: post } = useGetVacancyByIdQuery(id);
  const [
    editVacancy,
    { isLoading: isEditVacancyLoading, ...editVacancyRequestParams },
  ] = useEditVacancyMutation();

  useToastMessageRequest(editVacancyRequestParams);

  const initialVacancyValues = useMemo(
    () => ({
      title: post?.title ?? '',
      description: post?.description ?? '',
      content: post?.content ?? [],
      metaTitle: post?.metaTitle ?? '',
      metaH1: post?.metaH1 ?? '',
      metaDescription: post?.metaDescription ?? '',
      metaKeywords: post?.metaKeywords ?? '',
      metaSubTitle: post?.metaSubTitle ?? '',
      slug: post?.slug ?? '',
      reqId: post?.reqId ?? '',
      category: post?.category ?? '',
      noIndex: post?.noIndex ?? false,
    }),
    [post]
  );

  const onVacancySubmit = useCallback(
    ({ ...params }: any) => {
      editVacancy({
        id,
        ...params,
      });
    },
    [id]
  );

  return {
    initialVacancyValues,
    isEditVacancyLoading,
    onVacancySubmit,
  };
};
