import { IRichTextEditorInputProps } from './types';

import type { Editor } from '@ckeditor/ckeditor5-core';

export const useRichTextEditorInput = ({
  field,
  form: { touched, errors, setFieldValue },
}: IRichTextEditorInputProps) => {
  const onChange = (_: unknown, editor: Editor) => {
    setFieldValue(field.name, editor.getData());
  };

  const hasError = Boolean(touched[field.name] && errors[field.name]);

  return {
    hasError,
    onChange,
  };
};
