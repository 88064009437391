export enum ApiRoutes {
  Auth = 'admin/auth',
  WebsiteSettings = 'admin/settings',
  Users = 'admin/users',
  Images = 'images',
  Feedbacks = 'feedbacks',
  Posts = 'admin/posts',
  HistoryPoints = 'admin/history',
  Attachments = 'attachments',
  Logs = 'logs',
  Metadatas = 'metadata',
  Vacancies = 'admin/vacancies',
  PreviewImages = 'admin/preview-image',
}
