import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeletePostMutation } from '@/apis/posts';
import { useToastMessageRequest } from '@/hooks';
import { postsSelectors } from '@/redux/posts';
import { closeDeletePostModal } from '@/redux/posts/slice';

export const useDeletePostModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(postsSelectors.isDeletePostModalOpenSelector);
  const post = useSelector(postsSelectors.selectedPostForModalSelector);

  const [deletePost, { isSuccess, ...requestParams }] = useDeletePostMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeletePostModal());
  };

  const confirmDeleting = () => {
    deletePost(post?.id ?? '');
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, post, closeModal, confirmDeleting };
};
