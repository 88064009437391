import { Container, Typography } from '@mui/material';
import React from 'react';

import { PostForm } from '@/components';
import { postFormSchema } from '@/components/forms/PostForm/schemas';

import { useEditPostPage } from './useEditPostPage';

const EditPostPage: React.FC = () => {
  const { initialPostValues, isEditPostLoading, onPostSubmit } =
    useEditPostPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        Post #{initialPostValues.reqId}
      </Typography>
      <PostForm
        validationSchema={postFormSchema}
        initialValues={initialPostValues}
        isLoading={isEditPostLoading}
        onSubmit={onPostSubmit}
      />
    </Container>
  );
};

export default EditPostPage;
