import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  TableCell,
  TableRow,
  colors,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTableItem } from '@/hooks';
import { openDeletePostModal } from '@/redux/posts/slice';
import { envUtil, truncateString } from '@/utils';

import { styles } from './styles';

import type { IPostResponse } from '@/types';

const PostTableItem: React.FC<IPostResponse> = (post) => {
  const { api } = envUtil.getEnv();
  const { id, reqId, title, author, authorPosition, image, createdAt } = post;
  const imageUrl = image?.url;
  const { onEdit, onDelete } = useTableItem(openDeletePostModal);
  const createdDate = format(new Date(createdAt), 'dd.MM.yyyy HH:MM:SS');

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <RouterLink
          to={`edit/${id}`}
          style={{ textDecoration: 'none', color: colors.blue[600] }}
        >
          {reqId}
        </RouterLink>
      </TableCell>
      <TableCell component="th" scope="row">
        {truncateString(title)}
      </TableCell>
      <TableCell component="th" scope="row">
        {author}
      </TableCell>
      <TableCell component="th" scope="row">
        {authorPosition}
      </TableCell>
      <TableCell component="th" scope="row">
        <Link href={imageUrl} target="_blank" sx={styles.title}>
          <Box component="img" style={{ height: 30 }} src={imageUrl} />
        </Link>
      </TableCell>
      <TableCell align="left">{createdDate}</TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(post)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default PostTableItem;
