import { object, array, string, mixed } from 'yup';
import '@/constants/yupSettings';

import { text } from '@/constants';
import {PostContentBlockTypes} from "@/types";

const blockSchema = object({
  type: string().oneOf([
      PostContentBlockTypes.HTML,
    PostContentBlockTypes.Image,
    PostContentBlockTypes.Quote,
    PostContentBlockTypes.Link,
    PostContentBlockTypes.Bordered
  ]),
  data: object({}).when('type', (type) => {
    if (type === 'HTML') {
      return object({
        content: string(),
      });
    } else if (type === 'image') {
      return object({
        image: mixed(),
        description: string(),
      });
    } else if (type === 'quote') {
      return object({
        author: string(),
        authorPosition: string(),
        image: mixed(),
        quoteText: string(),
      });
    } else if (type === 'bordered') {
      return object({
        author: string(),
        authorPosition: string(),
        image: mixed(),
        quoteText: string(),
      });
    } return object({
        title: string(),
        link: string(),
        description: string(),
      });
  }),
});

export const postFormSchema = object({
  title: text,
  description: text,
  content: array()
    .of(blockSchema)
    .min(1),
}).required();
