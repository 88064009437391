import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IPostResponse, IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const postsApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Posts', 'Post'],
  endpoints: (builder) => ({
    getPosts: builder.query<IResponse<IPostResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Posts,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Posts' as const,
                id,
              })),
              { type: 'Posts', id: 'LIST' },
            ]
          : [{ type: 'Posts', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getPostById: builder.query<IPostResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Posts}/${id}`,
      }),
      providesTags: () => ['Post'],
    }),
    createPost: builder.mutation<IPostResponse, void>({
      query: (body) => ({
        url: `${ApiRoutes.Posts}/new`,
        body,
        method: 'POST',
      }),
    }),
    editPost: builder.mutation<IPostResponse, { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Posts}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Post'],
    }),
    deletePost: builder.mutation<void, string>({
      query: (id) => ({
        url: `${ApiRoutes.Posts}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPostsQuery,
  useCreatePostMutation,
  useEditPostMutation,
  useGetPostByIdQuery,
  useDeletePostMutation,
} = postsApi;
