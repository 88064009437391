import { string, number as yupNumber, mixed } from 'yup';

import { passwordRegexp } from './regexp';

export const email = string()
  .email()
  .trim()
  .required('Email is a required field');

export const firstName = string()
  .trim()
  .required('First name is a required field');

export const text = string().trim().required();

export const nickname = string()
  .trim()
  .required('Nickname is a required field');

export const number = yupNumber().required();

export const initialPassword = string()
  .trim()
  .required('Password is a required field')
  .matches(
    passwordRegexp,
    'Password must contain at least 8 characters, at least one number and both lower and uppercase letters'
  );

export const editPassword = string()
  .trim()
  .matches(
    passwordRegexp,
    'Password must contain at least 8 characters, at least one number and both lower and uppercase letters'
  );

export const fileExt = (allowedFileTypes: string[], allowUrl = false) =>
  mixed().test(
    'fileType',
    'This type of file is not allowed',
    (value: File | string) => {
      const type = (value as File)?.type ?? '';

      const regexList = allowedFileTypes.map(
        (x) => new RegExp(x.replace('.', '\\.').replace('*', '.*'))
      );

      return (
        regexList.some((regex) => regex.test(type)) ||
        (allowUrl && !!(value as string)?.length)
      );
    }
  );
