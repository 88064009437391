import { Button, Grid, MenuItem, Typography, Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import {
  EmailInput,
  PasswordInput,
  SelectInput,
  TextInput,
} from '@/components';
import { capitalize } from '@/utils';

import { userRoles, defaultInitialValue } from './constants';
import { styles } from './styles';

import type { IFormProps, IUserFormParams } from '@/types';

const UserForm: React.FC<IFormProps<IUserFormParams>> = ({
  validationSchema,
  isLoading,
  onSubmit,
  initialValues = defaultInitialValue,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="firstName"
                autoComplete="firstName"
                fullWidth
                component={TextInput}
                placeholder="First name"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="lastName"
                autoComplete="lastName"
                fullWidth
                component={TextInput}
                placeholder="Last name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="email"
                autoComplete="email"
                fullWidth
                component={EmailInput}
                placeholder="Email"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="password"
                autoComplete="current-password"
                fullWidth
                component={PasswordInput}
                placeholder="Password"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="userName"
                autoComplete="userName"
                fullWidth
                component={TextInput}
                placeholder="Nickname"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="role"
                placeholder="Role"
                component={SelectInput}
                size="small"
              >
                {userRoles.map((role) => (
                  <MenuItem value={role} key={role}>
                    <Typography>{capitalize(role)}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Box sx={styles.btnWrapper}>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
