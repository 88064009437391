import { categories } from '@/components/forms/VacancyForm/constants';
import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';

export const headCells = [
  { title: 'Id', width: 280 },
  { title: 'Title', width: 280 },
  { title: 'Slug', width: 280 },
  { title: 'Description', width: 280 },
  { title: 'Category', width: 280 },
  { title: 'Created', width: 280 },
  { title: '', width: 280 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  { name: 'slug', type: FilterTypes.Text },
  { name: 'description', type: FilterTypes.Text },
  {
    name: 'category',
    type: FilterTypes.Select,
    values: categories.map((category) => ({
      value: category,
      label: category,
    })),
  },
  null,
  null,
];
