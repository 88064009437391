export const userRoles = ['admin', 'superAdmin'];
export const userStatuses = ['active', 'inactive', 'pending'];

export const defaultInitialValue = {
  password: '',
  firstName: '',
  lastName: '',
  email: '',
  userName: '',
  role: 'admin',
};
